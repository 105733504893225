/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * liv-backend
 * OpenAPI spec version: 1.0
 */
import type {
  LivRentOperationSignedContractFilePOFADPutRequest,
  LivRentOperationSignedContractFilePOPDFPutRequest,
  LivRentOperationTenantApprovalStatusPOGet,
  LivRentOperationTenantPOCreateUpdateRequest,
  LivRentOperationTenantPOGetResponse
} from '../livBackend.schemas'
import { customInstance } from '../../src/apis/liv-backend-http-client';



  /**
 * @summary Create Rent Operation Tenant Approval
 */
export const createRentOperationTenantApprovalV1AdminRentOperationTenantApprovalPut = (
    livRentOperationTenantPOCreateUpdateRequest: LivRentOperationTenantPOCreateUpdateRequest,
 ) => {
      return customInstance<LivRentOperationTenantPOGetResponse>(
      {url: `/v1/admin/rent_operation/tenant_approval`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: livRentOperationTenantPOCreateUpdateRequest
    },
      );
    }
  /**
 * @summary Update Rent Operation Tenant Approval
 */
export const updateRentOperationTenantApprovalV1AdminRentOperationTenantApprovalPatch = (
    livRentOperationTenantPOCreateUpdateRequest: LivRentOperationTenantPOCreateUpdateRequest,
 ) => {
      return customInstance<LivRentOperationTenantPOGetResponse>(
      {url: `/v1/admin/rent_operation/tenant_approval`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: livRentOperationTenantPOCreateUpdateRequest
    },
      );
    }
  /**
 * @summary Create Rent Operation Tenant Approval From Ui
 */
export const createRentOperationTenantApprovalFromUiV1AdminRentOperationTenantApprovalFromUiPut = (
    livRentOperationTenantPOCreateUpdateRequest: LivRentOperationTenantPOCreateUpdateRequest,
 ) => {
      return customInstance<unknown>(
      {url: `/v1/admin/rent_operation/tenant_approval_from_ui`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: livRentOperationTenantPOCreateUpdateRequest
    },
      );
    }
  /**
 * @summary Get Rent Operation Tenant Approval Statuses
 */
export const getRentOperationTenantApprovalStatusesV1AdminRentOperationTenantApprovalStatusesGet = (
    
 ) => {
      return customInstance<LivRentOperationTenantApprovalStatusPOGet[]>(
      {url: `/v1/admin/rent_operation/tenant_approval/statuses`, method: 'GET'
    },
      );
    }
  /**
 * @summary Get Rent Operation Tenant Approval By Investigation Subject And Rent Operation
 */
export const getRentOperationTenantApprovalByInvestigationSubjectAndRentOperationV1AdminRentOperationTenantApprovalInvestigationSubjectUuidRentOperationUuidGet = (
    investigationSubjectUuid: string,
    rentOperationUuid: string,
 ) => {
      return customInstance<unknown>(
      {url: `/v1/admin/rent_operation/tenant_approval/${investigationSubjectUuid}/${rentOperationUuid}`, method: 'GET'
    },
      );
    }
  /**
 * @summary Put Signed Rent Contract Pdf
 */
export const putSignedRentContractPdfV1AdminRentOperationRentContractSignedRentContractPdfPut = (
    livRentOperationSignedContractFilePOPDFPutRequest: LivRentOperationSignedContractFilePOPDFPutRequest,
 ) => {
      return customInstance<unknown>(
      {url: `/v1/admin/rent_operation/rent_contract/signed_rent_contract_pdf`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: livRentOperationSignedContractFilePOPDFPutRequest
    },
      );
    }
  /**
 * @summary Put Signed Rent Contract Fad
 */
export const putSignedRentContractFadV1AdminRentOperationRentContractSignedRentContractFadPut = (
    livRentOperationSignedContractFilePOFADPutRequest: LivRentOperationSignedContractFilePOFADPutRequest,
 ) => {
      return customInstance<unknown>(
      {url: `/v1/admin/rent_operation/rent_contract/signed_rent_contract_fad`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: livRentOperationSignedContractFilePOFADPutRequest
    },
      );
    }
  /**
 * @summary Put Signed Policy Contract Pdf
 */
export const putSignedPolicyContractPdfV1AdminRentOperationRentContractSignedPolicyContractPdfPut = (
    livRentOperationSignedContractFilePOPDFPutRequest: LivRentOperationSignedContractFilePOPDFPutRequest,
 ) => {
      return customInstance<unknown>(
      {url: `/v1/admin/rent_operation/rent_contract/signed_policy_contract_pdf`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: livRentOperationSignedContractFilePOPDFPutRequest
    },
      );
    }
  /**
 * @summary Put Signed Policy Contract Fad
 */
export const putSignedPolicyContractFadV1AdminRentOperationRentContractSignedPolicyContractFadPut = (
    livRentOperationSignedContractFilePOFADPutRequest: LivRentOperationSignedContractFilePOFADPutRequest,
 ) => {
      return customInstance<unknown>(
      {url: `/v1/admin/rent_operation/rent_contract/signed_policy_contract_fad`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: livRentOperationSignedContractFilePOFADPutRequest
    },
      );
    }
  export type CreateRentOperationTenantApprovalV1AdminRentOperationTenantApprovalPutResult = NonNullable<Awaited<ReturnType<typeof createRentOperationTenantApprovalV1AdminRentOperationTenantApprovalPut>>>
export type UpdateRentOperationTenantApprovalV1AdminRentOperationTenantApprovalPatchResult = NonNullable<Awaited<ReturnType<typeof updateRentOperationTenantApprovalV1AdminRentOperationTenantApprovalPatch>>>
export type CreateRentOperationTenantApprovalFromUiV1AdminRentOperationTenantApprovalFromUiPutResult = NonNullable<Awaited<ReturnType<typeof createRentOperationTenantApprovalFromUiV1AdminRentOperationTenantApprovalFromUiPut>>>
export type GetRentOperationTenantApprovalStatusesV1AdminRentOperationTenantApprovalStatusesGetResult = NonNullable<Awaited<ReturnType<typeof getRentOperationTenantApprovalStatusesV1AdminRentOperationTenantApprovalStatusesGet>>>
export type GetRentOperationTenantApprovalByInvestigationSubjectAndRentOperationV1AdminRentOperationTenantApprovalInvestigationSubjectUuidRentOperationUuidGetResult = NonNullable<Awaited<ReturnType<typeof getRentOperationTenantApprovalByInvestigationSubjectAndRentOperationV1AdminRentOperationTenantApprovalInvestigationSubjectUuidRentOperationUuidGet>>>
export type PutSignedRentContractPdfV1AdminRentOperationRentContractSignedRentContractPdfPutResult = NonNullable<Awaited<ReturnType<typeof putSignedRentContractPdfV1AdminRentOperationRentContractSignedRentContractPdfPut>>>
export type PutSignedRentContractFadV1AdminRentOperationRentContractSignedRentContractFadPutResult = NonNullable<Awaited<ReturnType<typeof putSignedRentContractFadV1AdminRentOperationRentContractSignedRentContractFadPut>>>
export type PutSignedPolicyContractPdfV1AdminRentOperationRentContractSignedPolicyContractPdfPutResult = NonNullable<Awaited<ReturnType<typeof putSignedPolicyContractPdfV1AdminRentOperationRentContractSignedPolicyContractPdfPut>>>
export type PutSignedPolicyContractFadV1AdminRentOperationRentContractSignedPolicyContractFadPutResult = NonNullable<Awaited<ReturnType<typeof putSignedPolicyContractFadV1AdminRentOperationRentContractSignedPolicyContractFadPut>>>
