/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * liv-backend
 * OpenAPI spec version: 1.0
 */
import type {
  LivInvestigationScoreLivPOUpdateRequest
} from '../livBackend.schemas'
import { customInstance } from '../../src/apis/liv-backend-http-client';



  /**
 * @summary Get Investigation Operation Result By Investigation Subject And Investigation Operation
 */
export const getInvestigationOperationResultByInvestigationSubjectAndInvestigationOperationV1AdminInvestigationsInvestigationSubjectOperationResultInvestigationSubjectUuidInvestigationOperationUuidGet = (
    investigationSubjectUuid: string,
    investigationOperationUuid: string,
 ) => {
      return customInstance<unknown>(
      {url: `/v1/admin/investigations/investigation_subject_operation_result/${investigationSubjectUuid}/${investigationOperationUuid}`, method: 'GET'
    },
      );
    }
  /**
 * @summary Update Score Liv And Complete Investigation Subject
 */
export const updateScoreLivAndCompleteInvestigationSubjectV1AdminInvestigationsScoreLivAndCompleteInvestigationPatch = (
    livInvestigationScoreLivPOUpdateRequest: LivInvestigationScoreLivPOUpdateRequest,
 ) => {
      return customInstance<unknown>(
      {url: `/v1/admin/investigations/score_liv_and_complete_investigation`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: livInvestigationScoreLivPOUpdateRequest
    },
      );
    }
  /**
 * @summary Update Score Liv And Complete Investigation Subject From Ui
 */
export const updateScoreLivAndCompleteInvestigationSubjectFromUiV1AdminInvestigationsScoreLivAndCompleteInvestigationFromUiPatch = (
    livInvestigationScoreLivPOUpdateRequest: LivInvestigationScoreLivPOUpdateRequest,
 ) => {
      return customInstance<unknown>(
      {url: `/v1/admin/investigations/score_liv_and_complete_investigation_from_ui`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: livInvestigationScoreLivPOUpdateRequest
    },
      );
    }
  /**
 * @summary Run Investigation Process For Subject Manually
 */
export const runInvestigationProcessForSubjectManuallyV1AdminInvestigationsRunInvestigationProcessForSubjectManuallyUuidPost = (
    uuid: string,
 ) => {
      return customInstance<unknown>(
      {url: `/v1/admin/investigations/run_investigation_process_for_subject_manually/${uuid}`, method: 'POST'
    },
      );
    }
  export type GetInvestigationOperationResultByInvestigationSubjectAndInvestigationOperationV1AdminInvestigationsInvestigationSubjectOperationResultInvestigationSubjectUuidInvestigationOperationUuidGetResult = NonNullable<Awaited<ReturnType<typeof getInvestigationOperationResultByInvestigationSubjectAndInvestigationOperationV1AdminInvestigationsInvestigationSubjectOperationResultInvestigationSubjectUuidInvestigationOperationUuidGet>>>
export type UpdateScoreLivAndCompleteInvestigationSubjectV1AdminInvestigationsScoreLivAndCompleteInvestigationPatchResult = NonNullable<Awaited<ReturnType<typeof updateScoreLivAndCompleteInvestigationSubjectV1AdminInvestigationsScoreLivAndCompleteInvestigationPatch>>>
export type UpdateScoreLivAndCompleteInvestigationSubjectFromUiV1AdminInvestigationsScoreLivAndCompleteInvestigationFromUiPatchResult = NonNullable<Awaited<ReturnType<typeof updateScoreLivAndCompleteInvestigationSubjectFromUiV1AdminInvestigationsScoreLivAndCompleteInvestigationFromUiPatch>>>
export type RunInvestigationProcessForSubjectManuallyV1AdminInvestigationsRunInvestigationProcessForSubjectManuallyUuidPostResult = NonNullable<Awaited<ReturnType<typeof runInvestigationProcessForSubjectManuallyV1AdminInvestigationsRunInvestigationProcessForSubjectManuallyUuidPost>>>
