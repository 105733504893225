/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * liv-backend
 * OpenAPI spec version: 1.0
 */
import type {
  LivSendEmailOTPPORequest,
  LivSendOTPPOResponse,
  LivSendPhoneOTPPORequest
} from '../livBackend.schemas'
import { customInstance } from '../../src/apis/liv-backend-http-client';



  /**
 * @summary Otp Whatsapp
 */
export const otpWhatsappV1OtpWhatsappPost = (
    livSendPhoneOTPPORequest: LivSendPhoneOTPPORequest,
 ) => {
      return customInstance<LivSendOTPPOResponse>(
      {url: `/v1/otp/whatsapp`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: livSendPhoneOTPPORequest
    },
      );
    }
  /**
 * @summary Otp Email
 */
export const otpEmailV1OtpEmailPost = (
    livSendEmailOTPPORequest: LivSendEmailOTPPORequest,
 ) => {
      return customInstance<LivSendOTPPOResponse>(
      {url: `/v1/otp/email`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: livSendEmailOTPPORequest
    },
      );
    }
  export type OtpWhatsappV1OtpWhatsappPostResult = NonNullable<Awaited<ReturnType<typeof otpWhatsappV1OtpWhatsappPost>>>
export type OtpEmailV1OtpEmailPostResult = NonNullable<Awaited<ReturnType<typeof otpEmailV1OtpEmailPost>>>
