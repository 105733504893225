import { auth } from '@clerk/nextjs'

import { updateScoreLivAndCompleteInvestigationSubjectFromUiV1AdminInvestigationsScoreLivAndCompleteInvestigationFromUiPatch } from '@api-client/admin-investigation/admin-investigation'
import {
  createRentOperationTenantApprovalFromUiV1AdminRentOperationTenantApprovalFromUiPut,
  getRentOperationTenantApprovalStatusesV1AdminRentOperationTenantApprovalStatusesGet,
} from '@api-client/admin-rent-operation/admin-rent-operation'
import {
  addInvestigationSubjectV1InvestigationsInvestigationSubjectPut,
  assignPackageToInvestigationProcessV1InvestigationsAssignPackageToInvestigationProcessPut,
  createNewInterestedPartyForInvesgationProcessV1InvestigationsInterestedPartyPut,
  createNewInvestigationProcessV1InvestigationsPut,
  deleteInvestigationSubjectV1InvestigationsInvestigationSubjectInvestigationSubjectUuidInvestigationProcessUuidDelete,
  getInvestigationProcessByUuidV1InvestigationsUuidGet,
  getInvestigationProcessesCreatedByMeV1InvestigationsCreatedByMeGet,
  getInvestigationProcessesForMeAsInterestedPartyV1InvestigationsForMeAsInterestedPartyGet,
  getInvestigationProcessesForMeAsInvestigationSubjectV1InvestigationsForMeAsInvestigationSubjectGet,
  getInvestigationResultForSubjectV1InvestigationsInvestigationResultForSubjectUuidGet,
  getInvestigationSubjectByUuidV1InvestigationsInvestigationSubjectUuidGet,
  getPackageByUuidV1InvestigationsPackagesUuidGet,
  getPackagesEnabledV1InvestigationsPackagesEnabledGet,
  markInvestigationProcessAsPaymentProcessingV1InvestigationsMarkAsPaymentProcessingPost,
  patchInvestigationSubjectV1InvestigationsInvestigationSubjectPatch,
  removeInterestedPartyFromInvestigationProcessV1InvestigationsInterestedPartyInterestedPartyUuidInvestigationProcessUuidDelete,
  updateInterestedPartyForInvesgationProcessV1InvestigationsInterestedPartyPatch,
  updateInvestigationPayingUserV1InvestigationsUpdatePayingUserPatch,
  updateInvestigationSubjectInputForOperationsV1InvestigationsInvestigationSubjectInputsForOperationsPatch,
} from '@api-client/investigations/investigations'
import type {
  ContractMonthsDurationEnum,
  CountryNationalityISO31662Enum,
  GetInvestigationResultForSubjectV1InvestigationsInvestigationResultForSubjectUuidGet200,
  GetRentOperationsCreatedByMeV1RentOperationsCreatedByMeGetParams,
  GetRentOperationsForMeAsCollaboratorV1RentOperationsForMeAsCollaboratorGetParams,
  GetRentOperationsForMeAsInvestigatedSubjectV1RentOperationsForMeAsInvestigatedSubjectGetParams,
  GetRentOperationsForMeAsOwnerV1RentOperationsForMeAsOwnerGetParams,
  LivInvestigationMarkAsPaymentProcessingRequest,
  LivInvestigationPackagePOGet,
  LivInvestigationPOCreateRequest,
  LivInvestigationProcessInterestedPartyPOCreate,
  LivInvestigationProcessInterestedPartyPOUpdate,
  LivInvestigationProcessPOUpdatePackage,
  LivInvestigationScoreLivPOUpdateRequest,
  LivInvestigationSubjectInputsForOperationsPOUpdateRequest,
  LivInvestigationSubjectPOAddUserToInvestigate,
  LivInvestigationSubjectPOGetResponse,
  LivInvestigationSubjectPOUpdateUserToInvestigate,
  LivRentOperationApproveContractsPOPostRequest,
  LivRentOperationCollaboratorPOCreateRequest,
  LivRentOperationCollaboratorPOUpdateRequest,
  LivRentOperationMarkAsPaymentProcessingRequest,
  LivRentOperationPOCreateRequest,
  LivRentOperationPropertyDetailsPOPatchRequest,
  LivRentOperationPropertyOwnerPOCreateRequest,
  LivRentOperationPropertyOwnerPOUpdateRequest,
  LivRentOperationPropertyOwnerUserInputsForOperationsPO,
  LivRentOperationRentDetailsPOCreateRequest,
  LivRentOperationRentDetailsPOPatchRequest,
  LivRentOperationRentTermsPOCreateRequest,
  LivRentOperationRentTermsPOPatchRequest,
  LivRentOperationTenantApprovalStatusPOGet,
  LivRentOperationTenantPOCreateUpdateRequest,
  LivSendEmailOTPPORequest,
  LivSendPhoneOTPPORequest,
  LivStripeInvestigationCheckoutRequest,
  LivStripeRentOperationCheckoutRequest,
  LivUserAddressPO,
  LivUserBankAccountPOCreate,
  LivUserBankAccountPOSetBankAccountAsDefault,
  LivUserBankAccountPOUpdate,
  PaginatedResponseLivRentOperationTypePO,
  PaymentDayOfMonthEnum,
  PaymentFormEnum,
  PaymentFrequencyEnum,
  ResidencyTypeEnum,
} from '@api-client/livBackend.schemas'
import {
  otpEmailV1OtpEmailPost,
  otpWhatsappV1OtpWhatsappPost,
} from '@api-client/otp/otp'
import {
  addCollaboratorToRentOperationV1RentOperationsCollaboratorsPut,
  addRentOperationRentDetailsV1RentOperationsRentDetailsPut,
  addRentTermsToRentOperationV1RentOperationsRentTermsPut,
  createNewPropertyOwnerForRentOperationV1RentOperationsPropertyOwnerPut,
  createNewRentOperationWithPartialPropertyDetailsV1RentOperationsPut,
  deletePropertyOwnerV1RentOperationsPropertyOwnerPropertyOwnerUuidDelete,
  getRentOperationByUuidV1RentOperationsUuidGet,
  getRentOperationsCreatedByMeV1RentOperationsCreatedByMeGet,
  getRentOperationsForMeAsCollaboratorV1RentOperationsForMeAsCollaboratorGet,
  getRentOperationsForMeAsInvestigatedSubjectV1RentOperationsForMeAsInvestigatedSubjectGet,
  getRentOperationsForMeAsOwnerV1RentOperationsForMeAsOwnerGet,
  getRentOperationsTypesV1RentOperationsOperationTypesGet,
  markInvestigationProcessAsPaymentProcessingV1RentOperationsMarkAsPaymentProcessingPost,
  patchPropertyDetailsV1RentOperationsPropertyDetailsPatch,
  patchRentOperationRentDetailsV1RentOperationsRentDetailsPatch,
  patchRentTermsV1RentOperationsRentTermsPatch,
  postApproveContractsInRentOperationV1RentOperationsContractsApprovePost,
  updateCollaboratorToRentOperationV1RentOperationsCollaboratorsPatch,
  updatePropertyOwnerForRentOperationV1RentOperationsPropertyOwnerPatch,
} from '@api-client/rent-operations/rent-operations'
import {
  createCheckoutSessionToPayForInvestigationV1StripeCreateCheckoutSessionInvestigationPost,
  createCheckoutSessionToPayForRentOperationV1StripeCreateCheckoutSessionRentOperationPost,
} from '@api-client/stripe/stripe'
import {
  addUserBankAccountV1UserBankAccountsPut,
  deleteUserBankAccountV1UserBankAccountsUserBankAccountUuidUserClerkIdDelete,
  getUserBankAccountsV1UserBankAccountsUserClerkIdGet,
  setUserBankAccountAsDefaultV1UserBankAccountsSetAsDefaultPost,
  updateUserBankAccountV1UserBankAccountsPatch,
} from '@api-client/user-bank-accounts/user-bank-accounts'

export function getUser(): ReturnType<typeof auth> {
  const user = auth()
  return user
}

export async function getUserBankAccounts(userId: string) {
  const userBankAccounts =
    await getUserBankAccountsV1UserBankAccountsUserClerkIdGet(userId)
  return userBankAccounts
}

export async function setUserBankAccountAsDefault({
  userId,
  bankAccountUuid,
}: {
  userId: string
  bankAccountUuid: string
}) {
  const postData: LivUserBankAccountPOSetBankAccountAsDefault = {
    user_clerk_id: userId,
    uuid: bankAccountUuid,
  }
  const userBankAccountSetAsDefault =
    await setUserBankAccountAsDefaultV1UserBankAccountsSetAsDefaultPost(
      postData,
    )
  return userBankAccountSetAsDefault
}

export async function deleteUserBankAccount({
  bankAccountUUID,
  userId,
}: {
  bankAccountUUID: string
  userId: string
}) {
  const userBankAccountDelete =
    deleteUserBankAccountV1UserBankAccountsUserBankAccountUuidUserClerkIdDelete(
      bankAccountUUID,
      userId,
    )
  return userBankAccountDelete
}

export async function putUserBankAccount({
  userId,
  bankAccountNumber,
  bankName,
  bankAccountHolderName,
  isDefault,
}: {
  userId: string
  bankAccountNumber: string
  bankName: string
  bankAccountHolderName: string
  isDefault: boolean
}) {
  const payload: LivUserBankAccountPOCreate = {
    user_clerk_id: userId,
    bank_account_number: bankAccountNumber,
    bank_name: bankName,
    bank_account_holder_name: bankAccountHolderName,
    is_default: isDefault,
  }
  const userBankAccountCreate =
    await addUserBankAccountV1UserBankAccountsPut(payload)
  return userBankAccountCreate
}

export async function updateUserBankAccount({
  userId,
  bankAccountUUID,
  bankAccountHolderName,
}: {
  userId: string
  bankAccountUUID: string
  bankAccountHolderName: string
}) {
  const payload: LivUserBankAccountPOUpdate = {
    user_clerk_id: userId,
    uuid: bankAccountUUID,
    bank_account_holder_name: bankAccountHolderName,
  }
  const userBankAccountUpdate =
    await updateUserBankAccountV1UserBankAccountsPatch(payload)
  return userBankAccountUpdate
}

export async function getInvestigationsCreatedByMe() {
  const page = 1
  const pageSize = 24
  const params = {
    page,
    page_size: pageSize,
  }
  const investigationsCreatedByMe =
    await getInvestigationProcessesCreatedByMeV1InvestigationsCreatedByMeGet(
      params,
    )
  return investigationsCreatedByMe
}

export async function getInvestigationsForMeAsInterestedParty() {
  const page = 1
  const pageSize = 24
  const params = {
    page,
    page_size: pageSize,
  }
  const investigationsCreatedByMe =
    await getInvestigationProcessesForMeAsInterestedPartyV1InvestigationsForMeAsInterestedPartyGet(
      params,
    )
  return investigationsCreatedByMe
}

export async function getInvestigationsForMeAsInvestigatedSubject() {
  const page = 1
  const pageSize = 24
  const params = {
    page,
    page_size: pageSize,
  }
  const investigationsCreatedByMe =
    await getInvestigationProcessesForMeAsInvestigationSubjectV1InvestigationsForMeAsInvestigationSubjectGet(
      params,
    )
  return investigationsCreatedByMe
}

export async function putInvestigationProcess({
  names,
  lastNames,
  investigationSubjectEmail,
  phoneNumber,
  isPrimaryInvestigationSubject,
  rentOperationUUID,
}: {
  names: string
  lastNames: string
  investigationSubjectEmail: string
  phoneNumber: string
  isPrimaryInvestigationSubject: boolean
  rentOperationUUID?: string
}) {
  const payload: LivInvestigationPOCreateRequest = {
    names,
    last_names: lastNames,
    email: investigationSubjectEmail,
    phone_number: phoneNumber,
    is_primary_investigation_subject: isPrimaryInvestigationSubject,
    rent_operation_uuid: rentOperationUUID,
  }
  const newInvestigationProcessData =
    await createNewInvestigationProcessV1InvestigationsPut(payload)
  return newInvestigationProcessData
}

export async function addSubjectToInvestigationProcess({
  names,
  lastNames,
  investigationSubjectEmail,
  phoneNumber,
  investigationProcessUUID,
  isPrimaryInvestigationSubject,
}: {
  names: string
  lastNames: string
  investigationSubjectEmail: string
  phoneNumber: string
  investigationProcessUUID: string
  isPrimaryInvestigationSubject: boolean
}) {
  const payload: LivInvestigationSubjectPOAddUserToInvestigate = {
    email: investigationSubjectEmail,
    investigation_process_uuid: investigationProcessUUID,
    is_primary_investigation_subject: isPrimaryInvestigationSubject,
    last_names: lastNames,
    names,
    phone_number: phoneNumber,
  }
  const newInvestigationProcessData =
    await addInvestigationSubjectV1InvestigationsInvestigationSubjectPut(
      payload,
    )
  return newInvestigationProcessData
}

export async function deleteInvestigationSubjectFromInvestigation({
  investigationProcessUUID,
  investigationSubjectUUID,
}: {
  investigationProcessUUID: string
  investigationSubjectUUID: string
}) {
  const result =
    await deleteInvestigationSubjectV1InvestigationsInvestigationSubjectInvestigationSubjectUuidInvestigationProcessUuidDelete(
      investigationSubjectUUID,
      investigationProcessUUID,
    )
  return result
}

export async function putInvestigationInterestedParty({
  investigationProcessUUID,
  interestedPartyEmail,
  names,
  lastNames,
  phoneNumber,
}: {
  investigationProcessUUID: string
  interestedPartyEmail: string
  names: string
  lastNames: string
  phoneNumber: string
}) {
  const putInvestigationInterestedPartyData: LivInvestigationProcessInterestedPartyPOCreate =
    {
      investigation_process_uuid: investigationProcessUUID,
      email: interestedPartyEmail,
      names,
      last_names: lastNames,
      phone_number: phoneNumber,
    }
  const newInvestigationInterestedPartyData =
    await createNewInterestedPartyForInvesgationProcessV1InvestigationsInterestedPartyPut(
      putInvestigationInterestedPartyData,
    )
  return newInvestigationInterestedPartyData
}

export async function updateInvestigationInterestedParty({
  interestedPartyEmail,
  names,
  lastNames,
  interestedPartyUUID,
  phoneNumber,
}: {
  interestedPartyEmail: string
  names: string
  lastNames: string
  interestedPartyUUID: string
  phoneNumber: string
}) {
  const payload: LivInvestigationProcessInterestedPartyPOUpdate = {
    email: interestedPartyEmail,
    names,
    last_names: lastNames,
    interested_party_uuid: interestedPartyUUID,
    phone_number: phoneNumber,
  }
  const result =
    await updateInterestedPartyForInvesgationProcessV1InvestigationsInterestedPartyPatch(
      payload,
    )
  return result
}

export async function deleteInterestedPartyFromInvestigation({
  investigationProcessUUID,
  interestedPartyUUID,
}: {
  investigationProcessUUID: string
  interestedPartyUUID: string
}) {
  const result =
    await removeInterestedPartyFromInvestigationProcessV1InvestigationsInterestedPartyInterestedPartyUuidInvestigationProcessUuidDelete(
      interestedPartyUUID,
      investigationProcessUUID,
    )
  return result
}

export async function getCurrentInvestigationPackages(): Promise<
  LivInvestigationPackagePOGet[]
> {
  const currentPackages =
    await getPackagesEnabledV1InvestigationsPackagesEnabledGet()
  return currentPackages
}

export async function getInvestigationPackageByUUID(
  uuid: string,
): Promise<LivInvestigationPackagePOGet> {
  const currentPackage =
    await getPackageByUuidV1InvestigationsPackagesUuidGet(uuid)
  return currentPackage
}

export async function assingPackageToInvestigationProcess({
  investigationPackageUUID,
  investigationProcessUUID,
}: {
  investigationPackageUUID: string
  investigationProcessUUID: string
}) {
  const payload: LivInvestigationProcessPOUpdatePackage = {
    investigation_package_uuid: investigationPackageUUID,
    investigation_process_uuid: investigationProcessUUID,
  }
  const result =
    assignPackageToInvestigationProcessV1InvestigationsAssignPackageToInvestigationProcessPut(
      payload,
    )
  return result
}

export async function assingPayeeToInvestigationProcess({
  investigationProcessUUID,
  payingUserUUID,
}: {
  investigationProcessUUID: string
  payingUserUUID: string
}) {
  const payload = {
    investigation_process_uuid: investigationProcessUUID,
    paying_user_uuid: payingUserUUID,
  }
  const result =
    updateInvestigationPayingUserV1InvestigationsUpdatePayingUserPatch(payload)
  return result
}

export async function updateInvestigationUserInputs({
  uuid,
  curp,
  email,
  phoneNumber,
  sessionData,
  address,
  otp,
  nationality,
  residencyType,
  rfcForeigner,
  foreignNumber,
}: {
  uuid: string
  curp: string
  email?: string
  phoneNumber?: string
  sessionData: string
  address: LivUserAddressPO
  otp: string
  nationality: CountryNationalityISO31662Enum
  residencyType?: ResidencyTypeEnum
  rfcForeigner?: string
  foreignNumber?: string
}) {
  const payload: LivInvestigationSubjectInputsForOperationsPOUpdateRequest = {
    uuid,
    curp,
    email,
    phone_number: phoneNumber,
    session_data: sessionData,
    address,
    otp,
    nationality,
    residency_type: residencyType,
    rfc_foreigner: rfcForeigner,
    foreign_number: foreignNumber,
  }
  const result =
    updateInvestigationSubjectInputForOperationsV1InvestigationsInvestigationSubjectInputsForOperationsPatch(
      payload,
    )
  return result
}

export async function updateInvestigationSubjectUser({
  investigatedSubjectUUID,
  names,
  lastNames,
  investigationSubjectEmail,
  phoneNumber,
  isPrimaryInvestigationSubject,
}: {
  investigatedSubjectUUID: string
  names: string
  lastNames: string
  investigationSubjectEmail: string
  phoneNumber: string
  isPrimaryInvestigationSubject: boolean
}) {
  const payload: LivInvestigationSubjectPOUpdateUserToInvestigate = {
    investigation_subject_uuid: investigatedSubjectUUID,
    names,
    last_names: lastNames,
    email: investigationSubjectEmail,
    phone_number: phoneNumber,
    is_primary_investigation_subject: isPrimaryInvestigationSubject,
  }
  const result =
    await patchInvestigationSubjectV1InvestigationsInvestigationSubjectPatch(
      payload,
    )
  return result
}

export async function createInvestigationCheckoutSession({
  investigationProcessUUID,
}: {
  investigationProcessUUID: string
}) {
  const payload: LivStripeInvestigationCheckoutRequest = {
    investigation_process_uuid: investigationProcessUUID,
  }
  const result =
    await createCheckoutSessionToPayForInvestigationV1StripeCreateCheckoutSessionInvestigationPost(
      payload,
    )
  return result
}

export async function markInvestigationAsPaymentProcessing({
  investigationProcessUUID,
  stripeCheckoutSessionId,
}: {
  investigationProcessUUID: string
  stripeCheckoutSessionId: string
}) {
  const payload: LivInvestigationMarkAsPaymentProcessingRequest = {
    investigation_process_uuid: investigationProcessUUID,
    stripe_checkout_session_id: stripeCheckoutSessionId,
  }
  const result =
    await markInvestigationProcessAsPaymentProcessingV1InvestigationsMarkAsPaymentProcessingPost(
      payload,
    )
  return result
}

export function getInvestigationSubjectResult({
  investigationSubjectUUID,
}: {
  investigationSubjectUUID: string
}): Promise<GetInvestigationResultForSubjectV1InvestigationsInvestigationResultForSubjectUuidGet200> {
  const result =
    getInvestigationResultForSubjectV1InvestigationsInvestigationResultForSubjectUuidGet(
      investigationSubjectUUID,
    )
  return result
}

export async function sendOTPViaEmail(email: string) {
  const payload: LivSendEmailOTPPORequest = {
    to_email: email,
  }
  const result = otpEmailV1OtpEmailPost(payload)
  return result
}

export async function sendOTPViaWhatsapp(phoneNumber: string) {
  const payload: LivSendPhoneOTPPORequest = {
    to_phone_number: phoneNumber,
  }
  const result = otpWhatsappV1OtpWhatsappPost(payload)
  return result
}

export async function getInvestigationByUUID(uuid: string) {
  const result = getInvestigationProcessByUuidV1InvestigationsUuidGet(uuid)
  return result
}

export async function getInvestigationSubjectByUUID(
  uuid: string,
): Promise<LivInvestigationSubjectPOGetResponse> {
  const result =
    getInvestigationSubjectByUuidV1InvestigationsInvestigationSubjectUuidGet(
      uuid,
    )
  return result
}

// ------- Rent Operations -------

export async function putRentOperation({
  city,
  exteriorNumber,
  interiorNumber,
  monthlyRent,
  neighborhood,
  postalCode,
  state,
  street,
  rentOperationTypeUuid,
}: {
  city: string
  exteriorNumber: string
  interiorNumber?: string
  monthlyRent: number
  neighborhood: string
  postalCode: string
  state: string
  street: string
  rentOperationTypeUuid: string
}) {
  const payload: LivRentOperationPOCreateRequest = {
    city,
    exterior_number: exteriorNumber,
    interior_number: interiorNumber,
    monthly_rent: monthlyRent,
    neighborhood,
    postal_code: postalCode,
    state,
    street,
    rent_operation_type_uuid: rentOperationTypeUuid,
  }
  const newRentOperation =
    await createNewRentOperationWithPartialPropertyDetailsV1RentOperationsPut(
      payload,
    )
  return newRentOperation
}

export async function getRentOperationByUUID({ uuid }: { uuid: string }) {
  const rentOperation =
    await getRentOperationByUuidV1RentOperationsUuidGet(uuid)
  return rentOperation
}

export async function getRentOperationsCreatedByMe({
  page,
  pageSize,
}: {
  page?: number
  pageSize?: number
}) {
  const payload: GetRentOperationsCreatedByMeV1RentOperationsCreatedByMeGetParams =
    {
      page,
      page_size: pageSize,
    }
  const rentOperation =
    await getRentOperationsCreatedByMeV1RentOperationsCreatedByMeGet(payload)
  return rentOperation
}

export async function getRentOperationsForMeAsOwner({
  page,
  pageSize,
}: {
  page?: number
  pageSize?: number
}) {
  const payload: GetRentOperationsForMeAsOwnerV1RentOperationsForMeAsOwnerGetParams =
    {
      page,
      page_size: pageSize,
    }
  const rentOperation =
    await getRentOperationsForMeAsOwnerV1RentOperationsForMeAsOwnerGet(payload)
  return rentOperation
}

export async function getRentOperationsForMeAsInvestigatedSubject({
  page,
  pageSize,
}: {
  page?: number
  pageSize?: number
}) {
  const payload: GetRentOperationsForMeAsInvestigatedSubjectV1RentOperationsForMeAsInvestigatedSubjectGetParams =
    {
      page,
      page_size: pageSize,
    }
  const rentOperation =
    await getRentOperationsForMeAsInvestigatedSubjectV1RentOperationsForMeAsInvestigatedSubjectGet(
      payload,
    )
  return rentOperation
}

export async function getRentOperationsForMeAsCollaborator({
  page,
  pageSize,
}: {
  page?: number
  pageSize?: number
}) {
  const payload: GetRentOperationsForMeAsCollaboratorV1RentOperationsForMeAsCollaboratorGetParams =
    {
      page,
      page_size: pageSize,
    }
  const rentOperation =
    await getRentOperationsForMeAsCollaboratorV1RentOperationsForMeAsCollaboratorGet(
      payload,
    )
  return rentOperation
}

export async function putNewPropertyOwner({
  city,
  email,
  exteriorNumber,
  interiorNumber,
  lastNames,
  names,
  neighborhood,
  phoneNumber,
  postalCode,
  state,
  street,
  userInputsForOperations,
  rentOperationUUID,
}: {
  city: string
  email: string
  exteriorNumber: string
  interiorNumber?: string
  lastNames: string
  names: string
  neighborhood: string
  phoneNumber: string
  postalCode: string
  state: string
  street: string
  userInputsForOperations: LivRentOperationPropertyOwnerUserInputsForOperationsPO
  rentOperationUUID: string
}) {
  const payload: LivRentOperationPropertyOwnerPOCreateRequest = {
    city,
    email,
    exterior_number: exteriorNumber,
    interior_number: interiorNumber,
    last_names: lastNames,
    names,
    neighborhood,
    phone_number: phoneNumber,
    postal_code: postalCode,
    state,
    street,
    user_inputs_for_operations: userInputsForOperations,
    rent_operation_uuid: rentOperationUUID,
  }
  const propertyOwner =
    await createNewPropertyOwnerForRentOperationV1RentOperationsPropertyOwnerPut(
      payload,
    )
  return propertyOwner
}

export async function editPropertyOwner({
  city,
  email,
  exteriorNumber,
  interiorNumber,
  lastNames,
  names,
  neighborhood,
  phoneNumber,
  postalCode,
  state,
  street,
  userInputsForOperations,
  uuid,
}: {
  city: string
  email: string
  exteriorNumber: string
  interiorNumber?: string
  lastNames: string
  names: string
  neighborhood: string
  phoneNumber: string
  postalCode: string
  state: string
  street: string
  userInputsForOperations: LivRentOperationPropertyOwnerUserInputsForOperationsPO
  uuid: string
}) {
  const payload: LivRentOperationPropertyOwnerPOUpdateRequest = {
    city,
    email,
    exterior_number: exteriorNumber,
    interior_number: interiorNumber,
    last_names: lastNames,
    names,
    neighborhood,
    phone_number: phoneNumber,
    postal_code: postalCode,
    state,
    street,
    user_inputs_for_operations: userInputsForOperations,
    uuid,
  }
  const result =
    await updatePropertyOwnerForRentOperationV1RentOperationsPropertyOwnerPatch(
      payload,
    )
  return result
}

export async function deletePropertyOwner({
  propertyOwnerUUID,
}: {
  propertyOwnerUUID: string
}) {
  const result =
    deletePropertyOwnerV1RentOperationsPropertyOwnerPropertyOwnerUuidDelete(
      propertyOwnerUUID,
    )
  return result
}

export async function editProperty({
  city,
  exteriorNumber,
  hasStorage,
  housingComplex,
  interiorNumber,
  neighborhood,
  parkingIdentification,
  parkingSpaces,
  postalCode,
  propertyRecordIdentification,
  squareMeters,
  state,
  storageIdentification,
  street,
  uuid,
}: {
  city: string
  exteriorNumber: string
  hasStorage?: boolean
  housingComplex?: string
  interiorNumber?: string
  neighborhood: string
  parkingIdentification?: string
  parkingSpaces?: number
  postalCode: string
  propertyRecordIdentification?: string
  squareMeters?: number
  state: string
  storageIdentification?: string
  street: string
  uuid: string
}) {
  const payload: LivRentOperationPropertyDetailsPOPatchRequest = {
    city,
    exterior_number: exteriorNumber,
    has_storage: hasStorage,
    housing_complex_name: housingComplex,
    interior_number: interiorNumber,
    neighborhood,
    parking_identification: parkingIdentification,
    parking_spaces: parkingSpaces,
    postal_code: postalCode,
    property_record_identification: propertyRecordIdentification,
    square_meters: squareMeters,
    state,
    storage_identification: storageIdentification,
    street,
    uuid,
  }
  const result =
    await patchPropertyDetailsV1RentOperationsPropertyDetailsPatch(payload)
  return result
}

export async function addRentDetails({
  monthlyRent,
  paymentFrequency,
  paymentDayOfMonth,
  paymentForm,
  paymentAccount,
  maintenanceFee,
  maintenanceFeeCashPaymentInstructions,
  maintenanceFeeIncluded,
  maintenancePaymentAccount,
  maintenancePaymentDayOfMonth,
  maintenancePaymentForm,
  maintenancePaymentFrequency,
  rentOperationUUID,
}: {
  monthlyRent: number
  paymentFrequency: PaymentFrequencyEnum
  paymentDayOfMonth: PaymentDayOfMonthEnum
  paymentForm: PaymentFormEnum
  paymentAccount: string | null
  maintenanceFee: number | null
  maintenanceFeeIncluded: boolean
  maintenanceFeeCashPaymentInstructions: string | null
  maintenancePaymentAccount: string | null
  maintenancePaymentDayOfMonth: PaymentDayOfMonthEnum | null
  maintenancePaymentForm: PaymentFormEnum | null
  maintenancePaymentFrequency: PaymentFrequencyEnum | null
  rentOperationUUID: string
}) {
  const payload: LivRentOperationRentDetailsPOCreateRequest = {
    maintenance_fee: maintenanceFee,
    maintenance_fee_cash_payment_instructions:
      maintenanceFeeCashPaymentInstructions,
    maintenance_fee_included: maintenanceFeeIncluded,
    maintenance_payment_account: maintenancePaymentAccount,
    maintenance_payment_day_of_month: maintenancePaymentDayOfMonth,
    maintenance_payment_form: maintenancePaymentForm,
    maintenance_payment_frequency: maintenancePaymentFrequency,
    monthly_rent: monthlyRent,
    payment_account: paymentAccount,
    payment_day_of_month: paymentDayOfMonth,
    payment_form: paymentForm,
    payment_frequency: paymentFrequency,
    rent_operation_uuid: rentOperationUUID,
  }
  const result =
    await addRentOperationRentDetailsV1RentOperationsRentDetailsPut(payload)
  return result
}

export async function editRentDetails({
  maintenanceFee,
  maintenanceFeeCashPaymentInstructions,
  maintenanceFeeIncluded,
  maintenancePaymentAccount,
  maintenancePaymentDayOfMonth,
  maintenancePaymentForm,
  maintenancePaymentFrequency,
  monthlyRent,
  paymentAccount,
  paymentDayOfMonth,
  paymentForm,
  paymentFrequency,
  uuid,
}: {
  uuid: string
  monthlyRent: number
  paymentFrequency: PaymentFrequencyEnum
  paymentDayOfMonth: PaymentDayOfMonthEnum
  paymentForm: PaymentFormEnum
  paymentAccount: string | null
  maintenanceFee: number | null
  maintenanceFeeIncluded: boolean
  maintenanceFeeCashPaymentInstructions: string | null
  maintenancePaymentAccount: string | null
  maintenancePaymentDayOfMonth: PaymentDayOfMonthEnum | null
  maintenancePaymentForm: PaymentFormEnum | null
  maintenancePaymentFrequency: PaymentFrequencyEnum | null
}) {
  const payload: LivRentOperationRentDetailsPOPatchRequest = {
    maintenance_fee: maintenanceFee,
    maintenance_fee_cash_payment_instructions:
      maintenanceFeeCashPaymentInstructions,
    maintenance_fee_included: maintenanceFeeIncluded,
    maintenance_payment_account: maintenancePaymentAccount,
    maintenance_payment_day_of_month: maintenancePaymentDayOfMonth,
    maintenance_payment_form: maintenancePaymentForm,
    maintenance_payment_frequency: maintenancePaymentFrequency,
    monthly_rent: monthlyRent,
    payment_account: paymentAccount,
    payment_day_of_month: paymentDayOfMonth,
    payment_form: paymentForm,
    payment_frequency: paymentFrequency,
    uuid,
  }

  const result =
    await patchRentOperationRentDetailsV1RentOperationsRentDetailsPatch(payload)
  return result
}

export async function addRentTerms({
  contractMonthsDuration,
  monthsForSecurityDeposit,
  numberOfPeopleResiding,
  numberOfPets,
  paymentOnContractSignatureAccount,
  paymentOnContractSignatureAmount,
  paymentOnContractSignatureForm,
  rentOperationUUID,
  startDate,
  moveInDate,
}: {
  contractMonthsDuration: ContractMonthsDurationEnum
  monthsForSecurityDeposit: number
  numberOfPeopleResiding: number
  numberOfPets: number
  paymentOnContractSignatureAccount: string
  paymentOnContractSignatureAmount: number
  paymentOnContractSignatureForm: PaymentFormEnum
  rentOperationUUID: string
  startDate: string
  moveInDate: string
}) {
  const payload: LivRentOperationRentTermsPOCreateRequest = {
    contract_months_duration: contractMonthsDuration,
    months_for_security_deposit: monthsForSecurityDeposit,
    number_of_people_residing: numberOfPeopleResiding,
    number_of_pets: numberOfPets,
    payment_on_contract_signature_account: paymentOnContractSignatureAccount,
    payment_on_contract_signature_amount: paymentOnContractSignatureAmount,
    payment_on_contract_signature_form: paymentOnContractSignatureForm,
    start_date: startDate,
    move_in_date: moveInDate,
    rent_operation_uuid: rentOperationUUID,
  }
  const result =
    await addRentTermsToRentOperationV1RentOperationsRentTermsPut(payload)
  return result
}

export async function editRentTerms({
  contractMonthsDuration,
  monthsForSecurityDeposit,
  numberOfPeopleResiding,
  numberOfPets,
  paymentOnContractSignatureAccount,
  paymentOnContractSignatureAmount,
  paymentOnContractSignatureForm,
  startDate,
  moveInDate,
  uuid,
}: {
  contractMonthsDuration: ContractMonthsDurationEnum
  monthsForSecurityDeposit: number
  numberOfPeopleResiding: number
  numberOfPets: number
  paymentOnContractSignatureAccount: string
  paymentOnContractSignatureAmount: number
  paymentOnContractSignatureForm: PaymentFormEnum
  startDate: string
  moveInDate: string
  uuid: string
}) {
  const payload: LivRentOperationRentTermsPOPatchRequest = {
    contract_months_duration: contractMonthsDuration,
    months_for_security_deposit: monthsForSecurityDeposit,
    number_of_people_residing: numberOfPeopleResiding,
    number_of_pets: numberOfPets,
    payment_on_contract_signature_account: paymentOnContractSignatureAccount,
    payment_on_contract_signature_amount: paymentOnContractSignatureAmount,
    payment_on_contract_signature_form: paymentOnContractSignatureForm,
    start_date: startDate,
    move_in_date: moveInDate,
    uuid,
  }
  const result = await patchRentTermsV1RentOperationsRentTermsPatch(payload)
  return result
}

export async function addCollaborator({
  email,
  lastNames,
  names,
  phoneNumber,
  rentOperationUUID,
  hasPermissionForPropertyOwner,
  hasPermissionForPropertyDetails,
  hasPermissionForRentDetails,
  hasPermissionForRentTerms,
}: {
  email: string
  lastNames: string
  names: string
  phoneNumber: string
  rentOperationUUID: string
  hasPermissionForPropertyOwner: boolean
  hasPermissionForPropertyDetails: boolean
  hasPermissionForRentDetails: boolean
  hasPermissionForRentTerms: boolean
}) {
  const payload: LivRentOperationCollaboratorPOCreateRequest = {
    email,
    last_names: lastNames,
    names,
    phone_number: phoneNumber,
    rent_operation_uuid: rentOperationUUID,
    has_permission_for_property_owner: hasPermissionForPropertyOwner,
    has_permission_for_property_details: hasPermissionForPropertyDetails,
    has_permission_for_rent_details: hasPermissionForRentDetails,
    has_permission_for_rent_terms: hasPermissionForRentTerms,
  }
  const result =
    await addCollaboratorToRentOperationV1RentOperationsCollaboratorsPut(
      payload,
    )
  return result
}

export async function editCollaborator({
  email,
  lastNames,
  names,
  phoneNumber,
  uuid,
  hasPermissionForPropertyOwner,
  hasPermissionForPropertyDetails,
  hasPermissionForRentDetails,
  hasPermissionForRentTerms,
}: {
  email: string
  lastNames: string
  names: string
  phoneNumber: string
  uuid: string
  hasPermissionForPropertyOwner: boolean
  hasPermissionForPropertyDetails: boolean
  hasPermissionForRentDetails: boolean
  hasPermissionForRentTerms: boolean
}) {
  const payload: LivRentOperationCollaboratorPOUpdateRequest = {
    email,
    last_names: lastNames,
    names,
    phone_number: phoneNumber,
    uuid,
    has_permission_for_property_owner: hasPermissionForPropertyOwner,
    has_permission_for_property_details: hasPermissionForPropertyDetails,
    has_permission_for_rent_details: hasPermissionForRentDetails,
    has_permission_for_rent_terms: hasPermissionForRentTerms,
  }
  const result =
    await updateCollaboratorToRentOperationV1RentOperationsCollaboratorsPatch(
      payload,
    )
  return result
}

export async function createLegalPolicyCheckoutSession({
  rentOperationUUID,
}: {
  rentOperationUUID: string
}) {
  const payload: LivStripeRentOperationCheckoutRequest = {
    rent_operation_uuid: rentOperationUUID,
  }
  const result =
    await createCheckoutSessionToPayForRentOperationV1StripeCreateCheckoutSessionRentOperationPost(
      payload,
    )
  return result
}

export async function approveContract(rentOperationUUID: string) {
  const payload: LivRentOperationApproveContractsPOPostRequest = {
    rent_operation_uuid: rentOperationUUID,
  }

  const result =
    await postApproveContractsInRentOperationV1RentOperationsContractsApprovePost(
      payload,
    )
  return result
}

export async function markRentOperationAsPaymentProcessing({
  rentOperationUUID,
  stripeCheckoutSessionId,
}: {
  rentOperationUUID: string
  stripeCheckoutSessionId: string
}) {
  const payload: LivRentOperationMarkAsPaymentProcessingRequest = {
    rent_operation_uuid: rentOperationUUID,
    stripe_checkout_session_id: stripeCheckoutSessionId,
  }
  const result =
    await markInvestigationProcessAsPaymentProcessingV1RentOperationsMarkAsPaymentProcessingPost(
      payload,
    )
  return result
}

export async function getRentOperationTypes(): Promise<PaginatedResponseLivRentOperationTypePO> {
  const page = 1
  const pageSize = 24
  const params = {
    page,
    page_size: pageSize,
  }
  const rentOperationTypes =
    await getRentOperationsTypesV1RentOperationsOperationTypesGet(params)
  return rentOperationTypes
}

export async function submitApprovalOfSubjectToRentOperation({
  request,
}: {
  request: LivRentOperationTenantPOCreateUpdateRequest
}): Promise<unknown> {
  const resp =
    await createRentOperationTenantApprovalFromUiV1AdminRentOperationTenantApprovalFromUiPut(
      request,
    )
  return resp
}

export async function getTenantApprovalStatuses(): Promise<
  LivRentOperationTenantApprovalStatusPOGet[]
> {
  const resp =
    await getRentOperationTenantApprovalStatusesV1AdminRentOperationTenantApprovalStatusesGet()
  return resp
}

export async function submitLivScoreForInvestigationSubject({
  request,
}: {
  request: LivInvestigationScoreLivPOUpdateRequest
}): Promise<unknown> {
  const resp =
    await updateScoreLivAndCompleteInvestigationSubjectFromUiV1AdminInvestigationsScoreLivAndCompleteInvestigationFromUiPatch(
      request,
    )
  return resp
}
