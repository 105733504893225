/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * liv-backend
 * OpenAPI spec version: 1.0
 */
import type {
  GetRentOperationsCreatedByMeV1RentOperationsCreatedByMeGetParams,
  GetRentOperationsForMeAsCollaboratorV1RentOperationsForMeAsCollaboratorGetParams,
  GetRentOperationsForMeAsInvestigatedSubjectV1RentOperationsForMeAsInvestigatedSubjectGetParams,
  GetRentOperationsForMeAsOwnerV1RentOperationsForMeAsOwnerGetParams,
  GetRentOperationsTypesV1RentOperationsOperationTypesGetParams,
  LivRentOperationApproveContractsPOPostRequest,
  LivRentOperationCollaboratorPOCreateRequest,
  LivRentOperationCollaboratorPOGetResponse,
  LivRentOperationCollaboratorPOUpdateRequest,
  LivRentOperationMarkAsPaymentProcessingRequest,
  LivRentOperationPOCreateRequest,
  LivRentOperationPOGetResponse,
  LivRentOperationPOPutResponse,
  LivRentOperationPropertyDetailsPOCreatePatchResponse,
  LivRentOperationPropertyDetailsPOGet,
  LivRentOperationPropertyDetailsPOPatchRequest,
  LivRentOperationPropertyOwnerPOCreateRequest,
  LivRentOperationPropertyOwnerPOGet,
  LivRentOperationPropertyOwnerPOUpdateRequest,
  LivRentOperationRentDetailsPOCreatePatchResponse,
  LivRentOperationRentDetailsPOCreateRequest,
  LivRentOperationRentDetailsPOPatchRequest,
  LivRentOperationRentTermsPOCreatePatchResponse,
  LivRentOperationRentTermsPOCreateRequest,
  LivRentOperationRentTermsPOPatchRequest,
  PaginatedResponseLivRentOperationPOGetResponse,
  PaginatedResponseLivRentOperationTypePO
} from '../livBackend.schemas'
import { customInstance } from '../../src/apis/liv-backend-http-client';



  /**
 * @summary Add Collaborator To Rent Operation
 */
export const addCollaboratorToRentOperationV1RentOperationsCollaboratorsPut = (
    livRentOperationCollaboratorPOCreateRequest: LivRentOperationCollaboratorPOCreateRequest,
 ) => {
      return customInstance<LivRentOperationCollaboratorPOGetResponse>(
      {url: `/v1/rent_operations/collaborators`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: livRentOperationCollaboratorPOCreateRequest
    },
      );
    }
  /**
 * @summary Update Collaborator To Rent Operation
 */
export const updateCollaboratorToRentOperationV1RentOperationsCollaboratorsPatch = (
    livRentOperationCollaboratorPOUpdateRequest: LivRentOperationCollaboratorPOUpdateRequest,
 ) => {
      return customInstance<LivRentOperationCollaboratorPOGetResponse>(
      {url: `/v1/rent_operations/collaborators`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: livRentOperationCollaboratorPOUpdateRequest
    },
      );
    }
  /**
 * @summary Get Collaborator By Uuid
 */
export const getCollaboratorByUuidV1RentOperationsCollaboratorsUuidGet = (
    uuid: string,
 ) => {
      return customInstance<LivRentOperationCollaboratorPOGetResponse>(
      {url: `/v1/rent_operations/collaborators/${uuid}`, method: 'GET'
    },
      );
    }
  /**
 * @summary Post Approve Contracts In Rent Operation
 */
export const postApproveContractsInRentOperationV1RentOperationsContractsApprovePost = (
    livRentOperationApproveContractsPOPostRequest: LivRentOperationApproveContractsPOPostRequest,
 ) => {
      return customInstance<unknown>(
      {url: `/v1/rent_operations/contracts/approve`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: livRentOperationApproveContractsPOPostRequest
    },
      );
    }
  /**
 * @summary Get Rent Operations Types
 */
export const getRentOperationsTypesV1RentOperationsOperationTypesGet = (
    params?: GetRentOperationsTypesV1RentOperationsOperationTypesGetParams,
 ) => {
      return customInstance<PaginatedResponseLivRentOperationTypePO>(
      {url: `/v1/rent_operations/operation_types/`, method: 'GET',
        params
    },
      );
    }
  /**
 * @summary Patch Property Details
 */
export const patchPropertyDetailsV1RentOperationsPropertyDetailsPatch = (
    livRentOperationPropertyDetailsPOPatchRequest: LivRentOperationPropertyDetailsPOPatchRequest,
 ) => {
      return customInstance<LivRentOperationPropertyDetailsPOCreatePatchResponse>(
      {url: `/v1/rent_operations/property_details`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: livRentOperationPropertyDetailsPOPatchRequest
    },
      );
    }
  /**
 * @summary Get Rent Operation Property Details By Uuid
 */
export const getRentOperationPropertyDetailsByUuidV1RentOperationsPropertyDetailsUuidGet = (
    uuid: string,
 ) => {
      return customInstance<LivRentOperationPropertyDetailsPOGet>(
      {url: `/v1/rent_operations/property_details/${uuid}`, method: 'GET'
    },
      );
    }
  /**
 * @summary Create New Property Owner For Rent Operation
 */
export const createNewPropertyOwnerForRentOperationV1RentOperationsPropertyOwnerPut = (
    livRentOperationPropertyOwnerPOCreateRequest: LivRentOperationPropertyOwnerPOCreateRequest,
 ) => {
      return customInstance<LivRentOperationPropertyOwnerPOGet>(
      {url: `/v1/rent_operations/property_owner`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: livRentOperationPropertyOwnerPOCreateRequest
    },
      );
    }
  /**
 * @summary Update Property Owner For Rent Operation
 */
export const updatePropertyOwnerForRentOperationV1RentOperationsPropertyOwnerPatch = (
    livRentOperationPropertyOwnerPOUpdateRequest: LivRentOperationPropertyOwnerPOUpdateRequest,
 ) => {
      return customInstance<LivRentOperationPropertyOwnerPOGet>(
      {url: `/v1/rent_operations/property_owner`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: livRentOperationPropertyOwnerPOUpdateRequest
    },
      );
    }
  /**
 * @summary Delete Property Owner
 */
export const deletePropertyOwnerV1RentOperationsPropertyOwnerPropertyOwnerUuidDelete = (
    propertyOwnerUuid: string,
 ) => {
      return customInstance<void>(
      {url: `/v1/rent_operations/property_owner/${propertyOwnerUuid}`, method: 'DELETE'
    },
      );
    }
  /**
 * @summary Add Rent Operation Rent Details
 */
export const addRentOperationRentDetailsV1RentOperationsRentDetailsPut = (
    livRentOperationRentDetailsPOCreateRequest: LivRentOperationRentDetailsPOCreateRequest,
 ) => {
      return customInstance<LivRentOperationRentDetailsPOCreatePatchResponse>(
      {url: `/v1/rent_operations/rent_details`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: livRentOperationRentDetailsPOCreateRequest
    },
      );
    }
  /**
 * @summary Patch Rent Operation Rent Details
 */
export const patchRentOperationRentDetailsV1RentOperationsRentDetailsPatch = (
    livRentOperationRentDetailsPOPatchRequest: LivRentOperationRentDetailsPOPatchRequest,
 ) => {
      return customInstance<LivRentOperationRentDetailsPOCreatePatchResponse>(
      {url: `/v1/rent_operations/rent_details`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: livRentOperationRentDetailsPOPatchRequest
    },
      );
    }
  /**
 * @summary Add Rent Terms To Rent Operation
 */
export const addRentTermsToRentOperationV1RentOperationsRentTermsPut = (
    livRentOperationRentTermsPOCreateRequest: LivRentOperationRentTermsPOCreateRequest,
 ) => {
      return customInstance<LivRentOperationRentTermsPOCreatePatchResponse>(
      {url: `/v1/rent_operations/rent_terms`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: livRentOperationRentTermsPOCreateRequest
    },
      );
    }
  /**
 * @summary Patch Rent Terms
 */
export const patchRentTermsV1RentOperationsRentTermsPatch = (
    livRentOperationRentTermsPOPatchRequest: LivRentOperationRentTermsPOPatchRequest,
 ) => {
      return customInstance<LivRentOperationRentTermsPOCreatePatchResponse>(
      {url: `/v1/rent_operations/rent_terms`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: livRentOperationRentTermsPOPatchRequest
    },
      );
    }
  /**
 * @summary Get Rent Operations Created By Me
 */
export const getRentOperationsCreatedByMeV1RentOperationsCreatedByMeGet = (
    params?: GetRentOperationsCreatedByMeV1RentOperationsCreatedByMeGetParams,
 ) => {
      return customInstance<PaginatedResponseLivRentOperationPOGetResponse>(
      {url: `/v1/rent_operations/created_by_me`, method: 'GET',
        params
    },
      );
    }
  /**
 * @summary Get Rent Operations For Me As Investigated Subject
 */
export const getRentOperationsForMeAsInvestigatedSubjectV1RentOperationsForMeAsInvestigatedSubjectGet = (
    params?: GetRentOperationsForMeAsInvestigatedSubjectV1RentOperationsForMeAsInvestigatedSubjectGetParams,
 ) => {
      return customInstance<PaginatedResponseLivRentOperationPOGetResponse>(
      {url: `/v1/rent_operations/for_me_as_investigated_subject`, method: 'GET',
        params
    },
      );
    }
  /**
 * @summary Get Rent Operations For Me As Owner
 */
export const getRentOperationsForMeAsOwnerV1RentOperationsForMeAsOwnerGet = (
    params?: GetRentOperationsForMeAsOwnerV1RentOperationsForMeAsOwnerGetParams,
 ) => {
      return customInstance<PaginatedResponseLivRentOperationPOGetResponse>(
      {url: `/v1/rent_operations/for_me_as_owner`, method: 'GET',
        params
    },
      );
    }
  /**
 * @summary Get Rent Operations For Me As Collaborator
 */
export const getRentOperationsForMeAsCollaboratorV1RentOperationsForMeAsCollaboratorGet = (
    params?: GetRentOperationsForMeAsCollaboratorV1RentOperationsForMeAsCollaboratorGetParams,
 ) => {
      return customInstance<PaginatedResponseLivRentOperationPOGetResponse>(
      {url: `/v1/rent_operations/for_me_as_collaborator`, method: 'GET',
        params
    },
      );
    }
  /**
 * @summary Mark Investigation Process As Payment Processing
 */
export const markInvestigationProcessAsPaymentProcessingV1RentOperationsMarkAsPaymentProcessingPost = (
    livRentOperationMarkAsPaymentProcessingRequest: LivRentOperationMarkAsPaymentProcessingRequest,
 ) => {
      return customInstance<unknown>(
      {url: `/v1/rent_operations/mark_as_payment_processing`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: livRentOperationMarkAsPaymentProcessingRequest
    },
      );
    }
  /**
 * @summary Create New Rent Operation With Partial Property Details
 */
export const createNewRentOperationWithPartialPropertyDetailsV1RentOperationsPut = (
    livRentOperationPOCreateRequest: LivRentOperationPOCreateRequest,
 ) => {
      return customInstance<LivRentOperationPOPutResponse>(
      {url: `/v1/rent_operations`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: livRentOperationPOCreateRequest
    },
      );
    }
  /**
 * @summary Get Rent Operation By Uuid
 */
export const getRentOperationByUuidV1RentOperationsUuidGet = (
    uuid: string,
 ) => {
      return customInstance<LivRentOperationPOGetResponse>(
      {url: `/v1/rent_operations/${uuid}`, method: 'GET'
    },
      );
    }
  export type AddCollaboratorToRentOperationV1RentOperationsCollaboratorsPutResult = NonNullable<Awaited<ReturnType<typeof addCollaboratorToRentOperationV1RentOperationsCollaboratorsPut>>>
export type UpdateCollaboratorToRentOperationV1RentOperationsCollaboratorsPatchResult = NonNullable<Awaited<ReturnType<typeof updateCollaboratorToRentOperationV1RentOperationsCollaboratorsPatch>>>
export type GetCollaboratorByUuidV1RentOperationsCollaboratorsUuidGetResult = NonNullable<Awaited<ReturnType<typeof getCollaboratorByUuidV1RentOperationsCollaboratorsUuidGet>>>
export type PostApproveContractsInRentOperationV1RentOperationsContractsApprovePostResult = NonNullable<Awaited<ReturnType<typeof postApproveContractsInRentOperationV1RentOperationsContractsApprovePost>>>
export type GetRentOperationsTypesV1RentOperationsOperationTypesGetResult = NonNullable<Awaited<ReturnType<typeof getRentOperationsTypesV1RentOperationsOperationTypesGet>>>
export type PatchPropertyDetailsV1RentOperationsPropertyDetailsPatchResult = NonNullable<Awaited<ReturnType<typeof patchPropertyDetailsV1RentOperationsPropertyDetailsPatch>>>
export type GetRentOperationPropertyDetailsByUuidV1RentOperationsPropertyDetailsUuidGetResult = NonNullable<Awaited<ReturnType<typeof getRentOperationPropertyDetailsByUuidV1RentOperationsPropertyDetailsUuidGet>>>
export type CreateNewPropertyOwnerForRentOperationV1RentOperationsPropertyOwnerPutResult = NonNullable<Awaited<ReturnType<typeof createNewPropertyOwnerForRentOperationV1RentOperationsPropertyOwnerPut>>>
export type UpdatePropertyOwnerForRentOperationV1RentOperationsPropertyOwnerPatchResult = NonNullable<Awaited<ReturnType<typeof updatePropertyOwnerForRentOperationV1RentOperationsPropertyOwnerPatch>>>
export type DeletePropertyOwnerV1RentOperationsPropertyOwnerPropertyOwnerUuidDeleteResult = NonNullable<Awaited<ReturnType<typeof deletePropertyOwnerV1RentOperationsPropertyOwnerPropertyOwnerUuidDelete>>>
export type AddRentOperationRentDetailsV1RentOperationsRentDetailsPutResult = NonNullable<Awaited<ReturnType<typeof addRentOperationRentDetailsV1RentOperationsRentDetailsPut>>>
export type PatchRentOperationRentDetailsV1RentOperationsRentDetailsPatchResult = NonNullable<Awaited<ReturnType<typeof patchRentOperationRentDetailsV1RentOperationsRentDetailsPatch>>>
export type AddRentTermsToRentOperationV1RentOperationsRentTermsPutResult = NonNullable<Awaited<ReturnType<typeof addRentTermsToRentOperationV1RentOperationsRentTermsPut>>>
export type PatchRentTermsV1RentOperationsRentTermsPatchResult = NonNullable<Awaited<ReturnType<typeof patchRentTermsV1RentOperationsRentTermsPatch>>>
export type GetRentOperationsCreatedByMeV1RentOperationsCreatedByMeGetResult = NonNullable<Awaited<ReturnType<typeof getRentOperationsCreatedByMeV1RentOperationsCreatedByMeGet>>>
export type GetRentOperationsForMeAsInvestigatedSubjectV1RentOperationsForMeAsInvestigatedSubjectGetResult = NonNullable<Awaited<ReturnType<typeof getRentOperationsForMeAsInvestigatedSubjectV1RentOperationsForMeAsInvestigatedSubjectGet>>>
export type GetRentOperationsForMeAsOwnerV1RentOperationsForMeAsOwnerGetResult = NonNullable<Awaited<ReturnType<typeof getRentOperationsForMeAsOwnerV1RentOperationsForMeAsOwnerGet>>>
export type GetRentOperationsForMeAsCollaboratorV1RentOperationsForMeAsCollaboratorGetResult = NonNullable<Awaited<ReturnType<typeof getRentOperationsForMeAsCollaboratorV1RentOperationsForMeAsCollaboratorGet>>>
export type MarkInvestigationProcessAsPaymentProcessingV1RentOperationsMarkAsPaymentProcessingPostResult = NonNullable<Awaited<ReturnType<typeof markInvestigationProcessAsPaymentProcessingV1RentOperationsMarkAsPaymentProcessingPost>>>
export type CreateNewRentOperationWithPartialPropertyDetailsV1RentOperationsPutResult = NonNullable<Awaited<ReturnType<typeof createNewRentOperationWithPartialPropertyDetailsV1RentOperationsPut>>>
export type GetRentOperationByUuidV1RentOperationsUuidGetResult = NonNullable<Awaited<ReturnType<typeof getRentOperationByUuidV1RentOperationsUuidGet>>>
