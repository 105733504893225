import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  Canceler,
} from 'axios'
import Axios from 'axios'

function getCookie(name: string): string | null {
  if (typeof document === 'undefined') {
    return null
  }

  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop()?.split(';').shift() ?? null
  return null
}

const tokenInterceptor = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(
    (config) => {
      if (typeof window !== 'undefined') {
        const token = getCookie('__session')
        const newConfig = config
        newConfig.headers.user_session_token = token
        return newConfig
      }
      return config
    },
    (error) => {
      return Promise.reject(
        error instanceof Error ? error : new Error(String(error)),
      )
    },
  )

  return axiosInstance
}

const axiosInstance = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_BACKEND_URL,
  timeout: 30000, // Default timeout for all requests
})

export const AXIOS_INSTANCE = tokenInterceptor(axiosInstance)

export const setAxiosHeaders = (headers: Record<string, string>): void => {
  Object.assign(AXIOS_INSTANCE.defaults.headers, headers)
}

export const customInstance = <T>(
  config: AxiosRequestConfig,
): Promise<T> & { cancel: Canceler } => {
  const source = Axios.CancelToken.source()
  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token }).then(
    ({ data }: { data: T }) => data,
  ) as Promise<T> & { cancel: Canceler }

  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query')
  }

  return promise
}

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>
