/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * liv-backend
 * OpenAPI spec version: 1.0
 */
import type {
  LivUserBankAccountPOCreate,
  LivUserBankAccountPOGet,
  LivUserBankAccountPOSetBankAccountAsDefault,
  LivUserBankAccountPOUpdate
} from '../livBackend.schemas'
import { customInstance } from '../../src/apis/liv-backend-http-client';



  /**
 * @summary Add User Bank Account
 */
export const addUserBankAccountV1UserBankAccountsPut = (
    livUserBankAccountPOCreate: LivUserBankAccountPOCreate,
 ) => {
      return customInstance<LivUserBankAccountPOGet>(
      {url: `/v1/user_bank_accounts`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: livUserBankAccountPOCreate
    },
      );
    }
  /**
 * @summary Update User Bank Account
 */
export const updateUserBankAccountV1UserBankAccountsPatch = (
    livUserBankAccountPOUpdate: LivUserBankAccountPOUpdate,
 ) => {
      return customInstance<LivUserBankAccountPOGet>(
      {url: `/v1/user_bank_accounts`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: livUserBankAccountPOUpdate
    },
      );
    }
  /**
 * @summary Set User Bank Account As Default
 */
export const setUserBankAccountAsDefaultV1UserBankAccountsSetAsDefaultPost = (
    livUserBankAccountPOSetBankAccountAsDefault: LivUserBankAccountPOSetBankAccountAsDefault,
 ) => {
      return customInstance<LivUserBankAccountPOGet>(
      {url: `/v1/user_bank_accounts/set_as_default`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: livUserBankAccountPOSetBankAccountAsDefault
    },
      );
    }
  /**
 * @summary Delete User Bank Account
 */
export const deleteUserBankAccountV1UserBankAccountsUserBankAccountUuidUserClerkIdDelete = (
    userBankAccountUuid: string,
    userClerkId: string,
 ) => {
      return customInstance<void>(
      {url: `/v1/user_bank_accounts/${userBankAccountUuid}/${userClerkId}`, method: 'DELETE'
    },
      );
    }
  /**
 * @summary Get User Bank Accounts
 */
export const getUserBankAccountsV1UserBankAccountsUserClerkIdGet = (
    userClerkId: string,
 ) => {
      return customInstance<LivUserBankAccountPOGet[]>(
      {url: `/v1/user_bank_accounts/${userClerkId}`, method: 'GET'
    },
      );
    }
  export type AddUserBankAccountV1UserBankAccountsPutResult = NonNullable<Awaited<ReturnType<typeof addUserBankAccountV1UserBankAccountsPut>>>
export type UpdateUserBankAccountV1UserBankAccountsPatchResult = NonNullable<Awaited<ReturnType<typeof updateUserBankAccountV1UserBankAccountsPatch>>>
export type SetUserBankAccountAsDefaultV1UserBankAccountsSetAsDefaultPostResult = NonNullable<Awaited<ReturnType<typeof setUserBankAccountAsDefaultV1UserBankAccountsSetAsDefaultPost>>>
export type DeleteUserBankAccountV1UserBankAccountsUserBankAccountUuidUserClerkIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteUserBankAccountV1UserBankAccountsUserBankAccountUuidUserClerkIdDelete>>>
export type GetUserBankAccountsV1UserBankAccountsUserClerkIdGetResult = NonNullable<Awaited<ReturnType<typeof getUserBankAccountsV1UserBankAccountsUserClerkIdGet>>>
